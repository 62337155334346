/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Header from './header';
import './layout.css';
import Footer from './footer';
import useDimensions from 'react-use-dimensions';

const LayoutFull = ({ children, sidebar }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQueryFull {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const [ref, { height, width }] = useDimensions();
  const isMobileLayout = Boolean(width <= 800);
  const showSidebarOnSide = Boolean(sidebar && !isMobileLayout);

  return (
    <>
      <Header slimHeader fullLayout isMobileLayout={isMobileLayout} />
      <div
        ref={ref}
        style={{
          margin: `0 auto`,
          paddingTop: 0,
          minHeight: '100%',
          backgroundColor: '#222',
          display: showSidebarOnSide ? 'flex' : undefined,
          flexDirection: showSidebarOnSide ? 'row' : undefined,
        }}
      >
        <main>{children}</main>
      </div>
      <Footer isMobileLayout={isMobileLayout} />
    </>
  );
};

export default LayoutFull;
