import React, { useRef, useEffect } from 'react';
import {
  Player,
  FileProvider,
  YouTubeProvider,
  VimeoProvider,
} from '@vime-js/standard';
import { connect } from 'react-redux';
import { RootState } from '../store/rootReducer';
import { pause } from '../store/slices/player';

function VideoEmbed({
  url,
  pause,
  style = { marginBottom: '3em' },
  isControlsEnabled = true,
  autoplay = false,
}: {
  url: string;
  pause: () => void;
  style?: { [_: string]: number | string };
  isControlsEnabled?: boolean;
  autoplay?: boolean;
}) {
  const ref = useRef(null);
  useEffect(() => {
    const player = new Player({
      target: ref.current,
      props: {
        title: 'Video',
        isControlsEnabled,
        autoplay,
        src: url,
        providers: [FileProvider, YouTubeProvider, VimeoProvider],
      },
    });

    const { playing } = player.getStore();
    const unsubscribe = playing.subscribe(isPlaying => {
      if (isPlaying) {
        pause();
      }
    });

    return function destroyPlayer() {
      unsubscribe();
      player.$destroy();
    };
  });

  return (
    <div style={style}>
      <div ref={ref}></div>
    </div>
  );
}

export default connect((state: RootState) => ({}), { pause })(VideoEmbed);
