import React from 'react';
import SEO from '../components/seo';
import AlbumPromo from '../components/albumPromo';
import LayoutFull from '../components/layoutFull';
import VideoEmbed from '../embeds/videoEmbed';

const HomePage = () => (
  <LayoutFull>
    <SEO title="Home" />
    <AlbumPromo />
    <VideoEmbed
      style={{ paddingTop: '40px' }}
      url="https://www.youtube.com/watch?v=Bos4Gn3_eLI"
    />
  </LayoutFull>
);

export default HomePage;
